import axiosAuthoringInstance from 'api/axios-client';

export const getAllPdfs = (params) => {
  const newParams = new URLSearchParams();
  newParams.append('search_product', params.search_product);
  newParams.append('search_producer', params.search_producer);
  params.search_product_code &&
    newParams.append('search_product_code', params.search_product_code);
  params.search_cas_no &&
    newParams.append('search_cas_no', params.search_cas_no);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  params.language_code &&
    newParams.append('language_code', params.language_code);
  params.region?.map((el) => newParams.append('region', el));
  newParams.append('is_in_use_by_customer', params.is_in_use_by_customer);
  params.with_used_in_locations && 
    newParams.append('with_used_in_locations', params.with_used_in_locations);
  params.advanced_search && 
    newParams.append('advanced_search', params.advanced_search);
  if (params.minimum_year) {
    newParams.append('minimum_year', params.minimum_year);
  }
  if (params.allow_not_current_verison) {
    newParams.append('allow_not_current_verison', params.allow_not_current_verison);
  }
  if (params.search_include_barcode) {
    newParams.append('search_include_barcode', params.search_include_barcode);
  }
  return axiosAuthoringInstance
    .get('/pdfs/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSDetails = (sdsId, allowView, languageCode) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/`, {
      params: { allowView: allowView, language_code: languageCode },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPDFFile = (sdsId) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/getPDFFile/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getHeadingTranslations = (allowView) => {
  return axiosAuthoringInstance
    .get('/pdfs/headingsTranslation/', { params: { allowView: allowView } })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSWithNewerVersion = (sdsId) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/getWithNewVersion/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getEditedSDSInfoWithNewerVersion = (sdsId, replaceSdsId) => {
  return axiosAuthoringInstance
    .get(`v2/substances/${sdsId}/getCurrentSdsInfoAndNewerVersion/`,
      {
        params: {
          new_sds_id: replaceSdsId ? replaceSdsId : undefined
        }
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getOtherDataTags = () => {
  return axiosAuthoringInstance
    .get('/v2/user/getOtherDataTags/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getDiffWithNewerVersion = (sdsId, departmentId) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/getDiffWithNewVersion/`, {
      params: { department_id: departmentId },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCompletedSdsRequestInfo = (params) => {
  return axiosAuthoringInstance
    .get(`v2/substances/completedSdsRequestList/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export const getDiffWithNewerVersionV2 = (sdsId, departmentId) => {
  return axiosAuthoringInstance
    .get(`v2/substances/${sdsId}/getDiffWithNewVersion/`, {
      params: { department_id: departmentId },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSectionsHtml = (sdsId) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/getSectionsHtml/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceDetails = (substanceId, allowView, isSdsRequest) => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/`, {
      params: { allowView: allowView, is_sds_request: isSdsRequest },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const isValidStatementCode = (code) => {
  return axiosAuthoringInstance
    .get(`/v2/substances/isValidStatementCode/`, {
      params: { code: code },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export const getPdfsSimilarResults = (params) => {
  const newParams = new URLSearchParams();
  newParams.append('search_product', params.search_product);
  newParams.append('search_producer', params.search_producer);
  params.search_product_code &&
    newParams.append('search_product_code', params.search_product_code);
  params.search_cas_no &&
    newParams.append('search_cas_no', params.search_cas_no);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  newParams.append('minimum_year', params.minimum_year);
  newParams.append('is_in_use_by_customer', params.is_in_use_by_customer);
  newParams.append(
    'allow_not_current_verison',
    params.allow_not_current_verison
  );
  newParams.append('with_used_in_locations', params.with_used_in_locations);

  params.advanced_search && 
    newParams.append('advanced_search', params.advanced_search);
  params.partial_match && 
    newParams.append('partial_match', params.partial_match);
  params.region &&
    params.region.map((item) => newParams.append('region', item));
  params.language_code &&
    newParams.append('language_code', params.language_code);
  params.excluded_id &&
    params.excluded_id.map((id) => newParams.append('excluded_id', id));

  return axiosAuthoringInstance
    .get('/pdfs/similarResults/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
