import { createSelector } from '@reduxjs/toolkit';
import { CommonState } from './slice';
import { RootState } from 'constant';
import { PERMISSION } from 'enums/permissions.enums';

export const inputExternalSystemIdSelector = createSelector<
  [(state: RootState) => CommonState],
  string | number | null
>([(state) => state.common], (common) => common.inputExternalSystemId);

export const isPinnedSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.isPinned);

export const expandedItemSelector = createSelector<
  [(state: RootState) => CommonState],
  string | null
>([(state) => state.common], (common) => common.expandedItem);

export const locationIdSelector = createSelector<
  [(state: RootState) => CommonState],
  string | number | null
>([(state) => state.common], (common) => common.locationId);

export const pdfIdSelector = createSelector<
  [(state: RootState) => CommonState],
  string | number | null
>([(state) => state.common], (common) => common.pdfId);

export const openUpgradePlanPopupSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.openUpgradePlanPopup);

export const featureNotAvailableSelector = createSelector<
  [(state: RootState) => CommonState],
  PERMISSION | null
>([(state) => state.common], (common) => common.featureNotAvailable);

export const showTrialPlanToolTipSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.showTrialPlanToolTip);

export const expandMenuSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.expandMenu);

export const loadingModalSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.loadingModal);

export const openDrawerSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.openDrawer);

export const activeBarcodeSearchSelector = createSelector<
  [(state: RootState) => CommonState],
  boolean
>([(state) => state.common], (common) => common.activeBarcodeSearch);
